*{
  margin: 0;
  /* padding: 0; */
  font-family: var(--font-fontFamily);
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;

  /* transition: 1.1s ease-out; */
}
html{
  /* Global style variables */
  
  /* Colors */
  --color-primary: #2CC2B0;
  --color-background: #f4f4f4;
  --color-border: #707070;
  --color-tint-underlay: #D5FFE0;
  --color-text: #1c1c1c;
  --color-text-light: #424242;
  --color-footer: #34282C;

  /* Typography */
  --font-fontFamily: 'avenir', sans-serif;

  /* Spacing */
  --spacing-paddingHorizontal: 5vw;
  
  background-color: var(--color-background)
}

h1, h2, h3, h4, h5, h6 {
  color: var(--color-text);
}
div{
  box-sizing: border-box;
}
.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}