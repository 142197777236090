.projects{
  display: flex;
  flex-direction: column;
  background-color: #E5E5E5;
  align-items: center;
  justify-content: center;
  padding: 3vw 5vw 5vw 5vw
}
.projectsContainer{
  display: flex;
  flex-direction: column;
}
.projectCard {
  display: flex;
  flex-direction: column;
}
.projectCard span{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 15px 0;
}
.projectCard span p{
  font-style: italic;
}
.accordion{
  flex-direction: row-reverse;
  align-items: 'center',
}
.accordion p{
  font-weight: bold;
}
.accordion p:hover{
  text-decoration:underline;
}
.moreInfoContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.technologiesContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* width: 30%; */
  /* text-align: center; */
}


.technologiesContainer img{
  margin-bottom: 5px;
  width: auto;
}
.technologiesContainer h4{
  margin-bottom: 5px;
}


.photosContainer{
  display: flex;
  width: 70%;
  justify-content: flex-end;
}
.photosContainer img{
  margin-left: 10px;
  width: 20vw;
  object-fit: contain;
}

@media only screen and (min-width: 560px){
  .photosContainer img{
    width: 30vw;
  }
}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px){

}
