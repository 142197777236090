.contactView {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--color-background);
  padding: 3vw 0 3vw 0;
}
.contactView form{
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 400px;
}
.contactView form p {
  padding: 2vw 0 2vw 0;
}
.contactView form input{
  border: 0.1px solid var(--color-border);
  border-radius: 3px;
  margin-bottom: 5px;
  width: 47%;
  padding: 7px 0 7px 7px;
}
.contactView form textarea{
  border: 0.1px solid var(--color-border);
  padding: 7px 7px 7px 7px;
  border-radius: 3px;
  max-width: 100%;
  min-width: 100%;
  min-height: 34px;
}
.contactView form input[type=submit]{
  background-color: var(--color-primary);
  border-width: 0px;
  width: 100%;
  box-shadow: 0 3px 6px #00000030;
  margin-top: 2vw;
}
.contactView form input[type=submit]:hover{
  box-shadow: 0 3px 6px #00000050;
}
.contactView form span{
  display: flex;
  justify-content: space-between;
}