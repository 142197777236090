.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-footer);
  color: var(--color-background);
  padding: 10px 0 10px 0;
}
.footer p{
  font-size: 10pt;
}