.landingPage {
  /* padding: 3vw 5vw 0 5vw; */
}
.landingPage h3 {
  padding: 2vw 0 2vw 0;
  color: var(--color-text-light);
  font-size: 17pt;
  font-weight: 500;
}

.profileView{
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.headshot {
  /* max-width: 25%; */
  /* max-width: 300px; */
  width: 100%;
  
  align-self: flex-end;
  border-radius: 50%;
}

.skillsContainer{
  display: flex;
  justify-content: space-between;
  padding: 2vw 0 2vw 0;
  text-align: center;
}
.skillsContainer body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;

}

.hex{
  display: flex;
  width: 80px;
  height: 80px;
  /* background-image: url(http://placekitten.com/400/400/); */
  background-color: var(--color-primary);
  background-size: cover;
  position:relative;
  margin:10px;
  align-items: center;
  justify-content: center;
}
.hex:before, .hex:after{
  content:"";
  position:absolute;
  top:0px;height:40px;width:0px; /* 40 = height/2 */
  z-index:1;
  border:20px solid var(--color-background); /*change #FFF to your bg color*/
}
.hex:before{
  left:-20px; /* -1*borderWidth */
  border-right:40px solid transparent;/* width/2 */
}
.hex:after{
  left:40px; /* width/2 */
  border-left:40px solid transparent;/* width/2 */
}